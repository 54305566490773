const fr = {
  brand: 'ELITE STEAK HOUSE',
  welcome: 'bienvenue',
  welcometo: 'bienvenue à',
  baseline: 'Dans un cadre exceptionnel, Dégustez une viande sensationnelle',
  coming_soon: 'bientôt',
  menu: 'menu',
  backmenu: 'revenir au menu',
  enjoy_your_meal: 'Bon appétit',
  information: 'informations',
  //monday: 'Lundi : 12h00 à 22h00 (cuisine)',
  monday: 'Lundi : Fermé',
  exceptionopen: 'Ouverture exceptionnelle',
  tuesday: 'Mardi, Mercredi et Jeudi : 12h00 à 22h00 (cuisine)',
  friday: 'Vendredi, Samedi et Dimanche : 12h00 à 22h30 (cuisine)',
  service: 'Tous les jours service en continu',
  servicetime: 'NOUS AVONS 2 SERVICES :',
  firstservicetime: '1er à 19h',
  secondservicetime: '2e à 21h',
  gateau: "Les gâteaux d'extérieur ne sont pas acceptés !",
  following: 'Nos réseaux sociaux ',
  reservation: 'Réservation',
  contact: 'Contactez-nous',
  phone: '03 88 60 26 24',
  ramadan: 'Menu Spécial Ramadan',
  footertext:
    'Ambiance chaleureuse et décor raffiné au sein duquel notre Chef vous propose le meilleur de la viande. Elite Steak House propose des plats de qualité pour les amateurs de viande.',
};

export default fr;
