import { useState, useEffect } from 'react';
//import Image from 'next/image';
import Image from '../../components/Image';
// components
import { IconButtonAnimate } from '../../components/animate';
import MenuPopover from '../../components/menu-popover';
// @mui
import { Stack, MenuItem, Tooltip, Avatar } from '@mui/material';
// locales
import { useLocales } from '../../locales';

export default function LanguagePopover() {
  const { allLangs, currentLang, onChangeLang } = useLocales();

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = (newLang) => {
    onChangeLang(newLang);
    handleClosePopover();
  };

  return (
    <>
      <Tooltip title={currentLang.label}>
        <IconButtonAnimate onClick={handleOpenPopover} sx={{ p: 0 }}>
          <Avatar
            alt={currentLang.label}
            src={currentLang.icon}
            variant="rounded"
            sx={{ width: 40, height: 28 }}
          />
        </IconButtonAnimate>
      </Tooltip>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 70 }}
      >
        <Stack>
          {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value)}
            >
              <IconButtonAnimate sx={{ p: 0 }}>
                <Avatar
                  alt={option.label}
                  src={option.icon}
                  variant="rounded"
                  sx={{ width: 40, height: 28 }}
                />
              </IconButtonAnimate>
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
